import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import user1 from '../../src/Assets/user1.png';
import user2 from '../../src/Assets/user2.png';

function Testimonial(){
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1
      };
    return(
        <div className="TestimonialSection padding-y-large">
            <div className='padding-base'>
            <div className='position-relative'>
                <small className='text-voilet' ><b>CLIENT TALKS</b></small>
            </div>
            <h1 className='text-neutral-9'>Words from Our Clients</h1>
          </div>
                <Slider {...settings}>
      <div>
        <div className='Slide padding-base'>
            <h4>"I'm very thanksful to TargetSpace."</h4>
            <p className="quete">Your ability to consistently deliver exceptional work is truly commendable. I want to genuinely convey my gratitude to HirePeaks for their instrumental role in securing my current position. The extensive support provided during the placement process has left a lasting impression on me. Well done, and thank you once more.  </p>
            <div className="display-flex">
                <div className="user-thumb">
                <img src={user1} alt="user" className='UserThumb' width="50px"/>
                </div>
                <div className="margin-left-base">
                    <h4 className="margin-bottom-none margin-top-none">Mr. Jhon Doy</h4>
                    <p className="margin-top-none"><small>Web flow developer</small></p>
                </div>
            </div>
        </div>
      </div>
      <div>
      <div className='Slide padding-base'>
            <h4>"Great Support i get from."</h4>
            <p className="quete">I have been consistently impressed with the HirePeaks team’s attitude since the first day they contacted me for a position as a Consultant. I found one of the recruiter to be very engaging, dependable and high in integrity. I’m sure that his efficiency will help other candidates to find a good job and also to… </p>
            <div className="display-flex">
                <div className="user-thumb">
                <img src={user2} alt="user" className='UserThumb' width="50px"/>
                </div>
                <div className="margin-left-base">
                    <h4 className="margin-bottom-none margin-top-none">Mr. Jhon Doy</h4>
                    <p className="margin-top-none"><small>Web flow developer</small></p>
                </div>
            </div>
        </div>
      </div>
      <div>
      <div className='Slide padding-base'>
            <h4>"I'm very thanksful to TargetSpace."</h4>
            <p className="quete">Your ability to consistently deliver exceptional work is truly commendable. I want to genuinely convey my gratitude to HirePeaks for their instrumental role in securing my current position. The extensive support provided during the placement process has left a lasting impression on me. Well done, and thank you once more.  </p>
            <div className="display-flex">
                <div className="user-thumb">
                <img src={user1} alt="user" className='UserThumb' width="50px"/>
                </div>
                <div className="margin-left-base">
                    <h4 className="margin-bottom-none margin-top-none">Mr. Jhon Doy</h4>
                    <p className="margin-top-none"><small>Web flow developer</small></p>
                </div>
            </div>
        </div>
      </div>
      
    </Slider>
        </div>
    )
}
export default Testimonial