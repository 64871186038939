import React from "react";
import { useState } from "react";
import Discover from '../../src/Assets/TabImage_1.svg';
import Benefit from '../../src/Assets/TabImage_2.svg';
import Feature from '../../src/Assets/TabImage_3.svg';
function TabSection(){

    const[toggleState, setToggleState] = useState(1)
    const toggleTab = (index) =>{
        setToggleState(index);
        console.log(index)
    }
    return(
      <div className="background-voilet-lightest">
    <div className='TabSection padding-y-large'>
      <div className="margin-medium">
        <div className='display-flex TabButtonGroup justify-content-center'>
          <span className={toggleState === 1 ? "TabButton active" : "TabButton"}
          onClick={() => toggleTab(1)}>Discover</span>
          <span className={toggleState === 2 ? "TabButton active" : "TabButton"}
          onClick={() => toggleTab(2)}>Benefits</span>
          <span className={toggleState === 3 ? "TabButton active" : "TabButton"}
          onClick={() => toggleTab(3)}>Features</span>
        </div>
        <div className={toggleState === 1 ? "TabContent margin-top-l active" : "TabContent margin-top-l"}>
          <div className='display-flex flex-wrap'>
            <div className='column-6'>
              <div className='padding-l'>
                  <div className='position-relative'>
                    <small className='text-voilet' ><b>DISCOVER</b></small>
                  </div>
                <h1 className='text-neutral-9'>Discover the Power of Software Consulting with Target Space</h1>
                <p>We are a global consultancy that specializes in providing software solutions and consultants to corporations, startups, educational institutions and other clients in USA, UK, Asia. </p>
                <p>Target Space only staffs the best and most qualified software consultants, services providers and engineers. </p>
                <p>In order to keep our staff up-to-date with market trends, we train them on the latest, updated technologies</p>
             </div>
            </div>
            <div className='column-6'>
              <div className='background-primary-light padding-l theme-border-radius min-height-420 display-flex margin-medium'>
                <img src={Discover} alt="Discover" className='TabImage'/>
              </div>
            </div>
          </div>
        </div>
        <div className={toggleState === 2 ? "TabContent margin-top-l active" : "TabContent margin-top-l"}>
          <div className='display-flex flex-wrap'>
            <div className='column-6'>
              <div className='padding-l'>
              <div className='position-relative'>
                    <small className='text-voilet' ><b>BANAFITS</b></small>
                  </div>
                <h1 className='text-neutral-9'>With Target Space, you can get the expert software consulting and staffing services you need, when you need them.</h1>
                <p>We offer a wide range of software consulting and staffing services to help you with all your needs. From enterprise resource planning (ERP) to customer relationship management (CRM), we have the experience and expertise to help you get the most out of your software investment. We have a team of highly skilled and experienced professionals who are ready to assist you with anything you need. Whether you're looking for help with a specific project or need someone to staff your entire organization, we're here to help. Contact us today to learn more about our services and how we can help you achieve your goals. </p>
                
             </div>
            </div>
            <div className='column-6'>
              <div className='background-primary-light padding-l theme-border-radius min-height-420 display-flex margin-medium'>
                <img src={Benefit} alt="Benefit" className='TabImage'/>
              </div>
            </div>
          </div>
        </div>
        <div className={toggleState === 3 ? "TabContent margin-top-l active" : "TabContent margin-top-l"}>
          <div className='display-flex flex-wrap'>
            <div className='column-6'>
              <div className='padding-l'>
              <div className='position-relative'>
                    <small className='text-voilet' ><b>FEATURES</b></small>
                  </div>
                <h1 className='text-neutral-9'>Accelerate your IT journey with the best cost-effective tools</h1>
                <p>There's no doubt that technology has drastically changed the way we live and work. In today's fast-paced world, businesses need to be able to adapt quickly to stay competitive. This means having the right tools in place to help you work smarter, not harder.
Fortunately, there are a number of cost-effective tools that can help you accelerate your IT journey.
That's why we're excited to offer our agile tools, timesheets, and background verification services. With these tools, you'll be able to streamline your processes and get ahead of the competition.</p>
                
             </div>
            </div>
            <div className='column-6'>
              <div className='background-primary-light padding-l theme-border-radius min-height-420 display-flex margin-medium'>
                <img src={Feature} alt="Feature" className='TabImage'/>
              </div>
            </div>
          </div>
        </div>
    </div>
    </div>
    </div>
    )
}
export default TabSection