import React from "react";
import { useState } from "react";
import Image1 from '../../src/Assets/1.png';
import Image2 from '../../src/Assets/2.png';
import Image3 from '../../src/Assets/3.png';
import Image4 from '../../src/Assets/4.png';
function Achivments(){


    return(
<div className='background-primary'>
<div className='AchimentSection padding-y-large'>
        <div className='position-relative margin-medium'>
            <small className='text-secondary-light'><b>OUR ACHIVMENTS</b></small>
        </div>
        <h1 className='text-neutral-0 margin-medium'>What We Get</h1>
        <div className='display-flex flex-wrap'>
          <div className='column-3'>
          <div className="padding-x-medium">
            <img src={Image1} alt="image" className='achivmentImage InvertImage' width="100px"/>
                <h1 className='text-neutral-0'>100+</h1>
                <p className='text-neutral-0'>CANDIDATES LANDED DREAM JOBS</p>
            </div>
          </div>
          <div className='column-3'>
            <div className="padding-x-medium">
            <img src={Image2} alt="image" className='achivmentImage InvertImage' width="100px"/>
                <h1 className='text-neutral-0'>100+</h1>
                <p className='text-neutral-0'>AVALIABLE RECRUITES</p>
            </div>
          </div>
          <div className='column-3'>
          <div className="padding-x-medium">
          <img src={Image3} alt="image" className='achivmentImage InvertImage' width="100px"/>
                <h1 className='text-neutral-0'>97%</h1>
                <p className='text-neutral-0'>POSITIVE REVIEWS</p>
            </div>
          </div>
          <div className='column-3'>
          <div className="padding-x-medium">
          <img src={Image4} alt="image" className='achivmentImage InvertImage' width="100px"/>
                <h1 className='text-neutral-0'>75+</h1>
                <p className='text-neutral-0'>INTERNAL CLIENT</p>
            </div>
          </div>
        </div>
</div>
</div>
    )
}
export default Achivments