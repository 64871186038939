import LogoWhite from '../../src/logo.svg';
import Facebook from '../../src/Assets/fb.png';
import Twitter from '../../src/Assets/twitter.png';
import Instagram from '../../src/Assets/instagram.png';
import Wordpress from '../../src/Assets/wp.png';
import React from "react";
function Footer(){
    return(
    <div className='Footer  background-dull'>
        <div className='FooterContent padding-y-l border-bottom-neutral-0'>
            <div className='display-flex flex-wrap'>
                <div className='column-3'>
                   <div className='padding-medium'>
                    <div className="logo display-flex align-items-center margin-bottom-m"> 
                        <img src={LogoWhite} className="brand-logo margin-right-medium" alt="logo" />
                        <span className='font-size-medium font-weight-bold'>TargetSpace</span>
                    </div>
                    <p className='margin-bottom-m'>Our expert financial consultants provide solutions to help you achieve financial wealth. Trust us to guide you toward a brighter financial future.</p>
                    <div className='display-flex'>
                        <a href="#" className='display-flex align-items-center  font-small'>
                        <img src={Facebook} className="margin-right-base" alt="facebook" />
                        </a>
                        <a href="#" className='display-flex align-items-center font-small'>
                        <img src={Twitter} className="margin-right-base" alt="twitter" />  
                        </a>
                        <a href="#" className='display-flex align-items-center  font-small'>
                        <img src={Instagram} className="margin-right-base" alt="Instagram" /> 
                        </a>
                        <a href="#" className='display-flex align-items-center  font-small'>
                        <img src={Wordpress} className="margin-right-base" alt="Wordpress" /> 
                        </a>
                    </div>
                   </div>
                </div>
                <div className='column-3'>
                    <div className='padding-medium'>
                        <div className='font-size-medium margin-bottom-m'>Our Services</div>
                        <a href="#" className='display-block margin-bottom-m text-neutral-9'>Insurance Planning</a>
                        <a href="#" className='display-block margin-bottom-m text-neutral-9'>Estate Planning</a>
                        <a href="#" className='display-block margin-bottom-m text-neutral-9'>Optimization</a>
                        <a href="#" className='display-block margin-bottom-m text-neutral-9'>Debt Management</a>
                    </div>
                </div>
                <div className='column-3'>
                    <div className='padding-medium'>
                        <div className='font-size-medium margin-bottom-m'>Explore More</div>
                        <a href="#" className='display-block margin-bottom-m text-neutral-9'>About us</a>
                        <a href="#" className='display-block margin-bottom-m text-neutral-9'>Blog</a>
                        <a href="#" className='display-block margin-bottom-m text-neutral-9'>Site map</a>
                        <a href="#" className='display-block margin-bottom-m text-neutral-9'>Privacy</a>
                    </div>
                </div>
                <div className='column-3'>
                    <div className='padding-medium'>
                        <div className='font-size-medium margin-bottom-m'>Contact Details</div>
                        <a href="#" className='display-block margin-bottom-m text-neutral-9'>manager@targetspace.com</a>
              
                    </div>
                </div>
            </div>
        </div>
        <div className='PolicyContent'>
            <div className='display-flex flex-wrap'>
                <div className='column-8'>
                    <div className='padding-l'>TARGETSPACE 2024 © All rights reserved</div>
                </div>
                <div className='column-4'>
                    <div className='display-flex padding-l'>
                        <a href="#" className='text-neutral-9 margin-right-l'>Terms & Conditions</a>
                        <a href="#" className='text-neutral-9'>Privacy Policy</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Footer