import React from "react";
import map from '../../src/Assets/map.png';
function Contact(){
    return(
<div>
<div className='padding-m background-primary'>
      <div className='NewsletterSection padding-y-large'>
     
        <div className='display-flex flex-wrap'>
       
          <div className='column-6'>
            
          <div className='margin-l margin-top-none margin-bottom-none'>
          <div className='position-relative'><small className='text-secondary-light'><b>CONTACT US</b></small></div>
              <h1 className='text-neutral-0'>Get in Touch</h1>
                <div className='margin-bottom-m'>
                    <label className="text-neutral-0">Name</label>
                    <input type="text" className="InputField"/>
                </div>
                <div className='margin-bottom-m'>
                    <label className="text-neutral-0">Email</label>
                    <input type="email" className="InputField"/>
                </div>
                <div className='margin-bottom-m'>
                    <label className="text-neutral-0">Phone</label>
                    <input type="text" className="InputField"/>
                </div>
                <div className='margin-bottom-m'>
                    <label className="text-neutral-0">Message</label>
                    <input type="text" className="InputField"/>
                </div>
                <div>
                    <button className="btn btn-primary">SUBMIT</button>
                </div>
               
              
                </div>
          </div>
          <div className='column-6'>
            <div className="padding-base">
                <img src={map} alt="image" className='full-width'/>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
        )
    }
    
    export default Contact