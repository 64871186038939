import { useState, useEffect } from "react";
import { Link, animateScroll as scroll } from "react-scroll";

import BrandLogo from '../src/logo.svg';
import cross from '../src/Assets/cross.svg';
import Burgur from '../src/Assets/menu-burger.svg';
import logo from '../src/Assets/Frame.svg';
import Footer from './Components/Footer';
import TabSection from './Components/TabSection';
import Service from './Components/Service';
import Achivments from './Components/Achivments';
import Testimonial from './Components/Testimonial';
import Contact from './Components/Contact';


import './App.css';


function App() {

  const [scroll, setScroll] = useState(false);
 useEffect(() => {
   window.addEventListener("scroll", () => {
     setScroll(window.scrollY > 0);
   });
 }, []);

 const [isActive, setIsActive] = useState(false)
const toggleClass = () => {
    setIsActive(!isActive)
}

  return (
  <div  className={scroll ? "MainContent sticky-nav" : "MainContent default-nav"}>
    <div className='Header background-neutral-0'>
    <div className="Navbar">
           
           <div className="logo display-flex align-items-center padding-medium"> 
           <span onClick={toggleClass} className='MobileNav'>
               <img src={Burgur}  alt="burgur" className='full-width'/>
           </span>
               <img src={BrandLogo} className="brand-logo margin-right-medium" alt="logo" />
               <span className='font-size-medium font-weight-bold'>TargetSpace</span>
           </div>
           <div>
           <div className={isActive ? 'Navigation full-height active' : 'Navigation full-height'}>
               <span className='BurgurMenu' onClick={toggleClass}>
                   <img src={cross}  alt="cross" className='full-width'/>
               </span>
               <Link className='padding-x-medium padding-y-base display-flex align-items-center text-neutral-9 position-relative' to="Home" 
               activeClass="active"
               spy={true}
               smooth={true}
               offset={-70}
               duration={700}>Home</Link>
               <Link className='padding-x-medium padding-y-base display-flex align-items-center text-neutral-9 position-relative' to="AboutUs" 
               activeClass="active"
               spy={true}
               smooth={true}
               offset={-70}
               duration={700}>About Us</Link>
             
               <Link className='padding-x-medium padding-y-base display-flex align-items-center text-neutral-9 position-relative' to="WhatWeDo"
               activeClass="active"
               spy={true}
               smooth={true}
               offset={-70}
               duration={700}>Services</Link>
               <Link className='padding-x-medium padding-y-base display-flex align-items-center text-neutral-9 position-relative' to="Achivments"
               activeClass="active"
               spy={true}
               smooth={true}
               offset={-70}
               duration={700}>Achivments</Link>
               <Link className='padding-x-medium padding-y-base display-flex align-items-center text-neutral-9 position-relative' to="Testimonial"
               activeClass="active"
               spy={true}
               smooth={true}
               offset={-70}
               duration={700}>Testimonial</Link>
               <Link className='padding-x-medium padding-y-base display-flex align-items-center text-neutral-9 position-relative' to="ContactUs"
               activeClass="active"
               spy={true}
               smooth={true}
               offset={-70}
               duration={700}>Contact Us</Link>
             
           </div>
           </div>
           
   </div>
    </div>
    <div className='Banner' id="Home"><div className='YellowOverlay'>
      <div className='BannerContent'>
          <img src={logo} alt="logo" />
      </div>
    </div></div>
    <div id="AboutUs">
    <TabSection></TabSection>
    </div>
    <div id="WhatWeDo">
    <Service></Service>
    </div>
    <div id="Achivments">
    <Achivments></Achivments>
    </div>
    <div id="Testimonial">
    <Testimonial></Testimonial>
    </div>
    <div id="ContactUs">
   <Contact></Contact>
   </div>
    <Footer></Footer>
  </div>
    
 
      
  );
}

export default App;
