import React from "react";
import { useState } from "react";
import Group1 from '../Assets/staff.png';
import Group2 from '../Assets/customer-service-agent.png';
import Group3 from '../Assets/president.png';
function Service(){


    return(

        <div className='WhatWeDo padding-y-l'>
        <div className='DoContent padding-y-l'>
          <div className='padding-base'>
            <div className='position-relative'>
                <small className='text-voilet' ><b>SERVICES</b></small>
            </div>
            <h1 className='text-neutral-9'>What We Do</h1>
          </div>
          
          <div className='display-flex flex-wrap'>
              <div className='column-4'>
                <div className='padding-l'>
                  <img src={Group1} alt="image" width="70px"/>
                  <h3 className='margin-bottom-m margin-top-m'>Staffing</h3>
                  <p className='margin-bottom-m'>TargetSpace is your gateway to unparalleled staffing solutions designed to elevate your career or business to new heights. With a focus on personalized service and a deep understanding of the industry landscape, we connect exceptional talent with the perfect opportunities across the USA</p>
                    <a href="" className='text-primary semi-bold'>Read More</a>
                </div>
              </div>
              <div className='column-4'>

              <div className='padding-l'>
                  <img src={Group2} alt="image" width="70px"/>
                  <h3 className='margin-bottom-m margin-top-m'>Career Guidance</h3>
                  <p className='margin-bottom-m'>Your dedicated career advisor, guiding individuals across the USA with personalized support and industry expertise. We connect you with opportunities that align with your skills and aspirations, ensuring a perfect fit for your career goals. Partner with TargetSpace for confidence in navigating your career journey.</p>
                    <a href="" className='text-primary semi-bold'>Read More</a>
                </div>

              </div>
              <div className='column-4'>

              <div className='padding-l'>
                  <img src={Group3} alt="image" width="70px"/>
                  <h3 className='margin-bottom-m margin-top-m'>Job Guarantee</h3>
                  <p className='margin-bottom-m'>TargetSpace assures your success: Our 90-Day Job Guarantee program offers expert training and market insights for seamless workforce integration. Backed by our extensive employer network, we promise job placement within 90 days of program completion. Join us for guaranteed success in your chosen field.</p>
                    <a href="" className='text-primary semi-bold'>Read More</a>
                </div>

              </div>

       
          </div>
        </div>
    </div>
   
    )
}
export default Service